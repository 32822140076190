//List of regex url patterns where the left menu should be hidden
export const urlPatternsToHideLeftMenu = [
  /\/tasks\/global-settings\/template/,
  /\/notices\/global-settings\/template/,
  // /\/templates\/tasks\/./, // never show the sidebar on any new templates/tasks/* pages
];

export const navContentStyle = {
  marginLeft: "318px",
  width: "calc(100% - 318px)",
  padding: "24px 16px",
  height: "calc(100vh - 56px)",
};

const subNavTemplates = [
  /\/tasks\/global-settings/,
  /\/templates\/tasks/,
  /\/global-settings\/tax-return/,
  /\/communications\/global-settings\/templates/,
  /\/notices\/global-settings/,
  /\/admin\/global-settings\/service-templates/,
  /\/letters\/global-settings\/templates/,
  /\/letters\/global-settings\/boilerplate/,
  /\/files\/global-settings\/folder-templates/,
  /\/notifications\/global-settings\/messages/,
];

export function shouldHideLeftMenu() {
  let shouldHideSubNavItems = [];
  const { hash } = window.location;

  shouldHideSubNavItems = shouldHideSubNavItems.concat(subNavTemplates);
  urlPatternsToHideLeftMenu.concat(/\/templates\/tasks\/./);
  const patterns = urlPatternsToHideLeftMenu.concat(shouldHideSubNavItems);

  return urlPatternMatch(hash, patterns);
}

export function isTemplatePage() {
  const { hash } = window.location;
  const patterns = urlPatternsToHideLeftMenu.concat(subNavTemplates);

  return urlPatternMatch(hash, patterns);
}

export function urlPatternMatch(url, patterns) {
  return patterns.reduce((results, regex) => (regex.test(url) ? [...results, true] : results), []).length;
}
