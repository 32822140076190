import auth, { isWithinLicenseLimit } from "cp-client-auth!sofe";
import { getTenant } from "src/pages/tenant.resource.js";
import { renderLicensePaywallModal } from "src/exports/license-paywall-modal.component";
import { handleError } from "src/error";

export function refreshLicense() {
  // A new token needs to be generated with new permissions before we refresh
  // If the refresh fails, we don't have a valid token in the first place which
  // should send us to login
  auth
    .refreshAuthToken({})
    .then(() => window.location.reload())
    .catch(() => window.location.reload());
}

export function checkLicenses() {
  getTenant().subscribe((tenant) => {
    const licenses = { ...tenant.licenses };

    const invalidLicenseNames = Object.keys(licenses).filter(
      (type) => !isWithinLicenseLimit(type, licenses, tenant.client_limit, tenant.free_transcripts)
    );

    if (!invalidLicenseNames.length) return;

    const invalidLicenses = invalidLicenseNames.reduce((invalidLicenses, licenseName) => {
      return {
        ...invalidLicenses,
        [licenseName]: licenses[licenseName],
      };
    }, {});

    if (invalidLicenses) {
      renderLicensePaywallModal(invalidLicenses);
    }
  }, handleError);
}
