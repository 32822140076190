import { hasAccess } from "cp-client-auth!sofe";
import { featureEnabled } from "feature-toggles!sofe";

export const getAdminMenuItems = (props) => {
  const userHasAccess = hasAccess(props.loggedInUser, true);
  const { hasRenameBeta } = props;
  return [
    {
      icon: "misc-paintbrush",
      shouldDisplay: userHasAccess("company_settings_custom_branding"),
      title: "Custom Branding",
      url: "#/white-label/global-settings",
    },
    {
      icon: "person-people",
      shouldDisplay: userHasAccess("company_settings_team_members"),
      title: "Team Organization",
      url: "#/global-settings/team-organization",
    },
    {
      icon: "af-line-square-up",
      shouldDisplay: props.hasOnboarding,
      title: "Onboarding",
      url: "#/global-settings/onboarding",
    },
    {
      icon: "person-lock",
      shouldDisplay:
        userHasAccess("company_settings_roles_and_permissions") ||
        userHasAccess(["company_settings_team_members", "company_settings_roles_and_permissions_view"]),
      title: "Access and Permissions",
      url: "#/global-settings/permission-sets",
    },
    {
      icon: "misc-text-input",
      shouldDisplay: userHasAccess("company_settings_custom_fields"),
      title: "Custom Fields",
      url: "#/global-settings/custom-fields",
    },
    {
      icon: "misc-card-gears",
      shouldDisplay: userHasAccess("company_settings_account_management"),
      title: "Account Management",
      url: "#/global-settings/account-management",
    },
    {
      icon: "billing-square-open",
      shouldDisplay: userHasAccess("company_settings_billing") || userHasAccess("integrations_configure"),
      title: "Billing Settings",
      url: "#/global-settings/billing",
    },
    {
      icon: "file-gear",
      shouldDisplay:
        featureEnabled("toggle_doc_automation_rename") && userHasAccess("company_settings_files") && hasRenameBeta,
      title: "Document Settings",
      url: "#/global-settings/files",
    },
    {
      icon: "organize-tag",
      shouldDisplay: userHasAccess("company_settings_tags"),
      title: "Tags",
      url: "#/global-settings/tags",
    },
  ];
};

export const getSettingsMenuItems = (props) => {
  const userHasAccess = hasAccess(props.loggedInUser);

  return [
    {
      icon: "communication-envelope",
      shouldDisplay: userHasAccess("communications"),
      title: "Email and Calendar",
      subItems: [
        {
          shouldDisplay: userHasAccess("communications"),
          title: "Accounts",
          url: "#/communications/global-settings/email",
        },
        {
          shouldDisplay: userHasAccess("clients_bulk_email"),
          title: "Bulk Email History",
          url: "#/communications/global-settings/bulk-email-history",
        },
      ],
    },
    {
      icon: "misc-bell",
      shouldDisplay: userHasAccess("notifications"),
      title: "Notifications",
      url: "#/notifications/global-settings/preferences",
    },
    {
      icon: "shape-circles-connected",
      shouldDisplay: true,
      title: "Integrations",
      url: "#/global-settings/integrations",
    },
  ];
};
