// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-settings-submenu-item-styles__subItem {
  padding: 8px 0 8px 24px;
}

.src-settings-submenu-item-styles__subItem:first-child {
  padding-top: 24px;
  border-top: 1px solid #e9e9e9;
}

.src-settings-submenu-item-styles__subItem:last-child {
  padding-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
}

.src-settings-submenu-item-styles__subItem:focus {
  text-decoration: none;
}

.src-settings-submenu-item-styles__subItem:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/settings-submenu-item.styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;EACE,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".subItem {\n  padding: 8px 0 8px 24px;\n}\n\n.subItem:first-child {\n  padding-top: 24px;\n  border-top: 1px solid #e9e9e9;\n}\n\n.subItem:last-child {\n  padding-bottom: 24px;\n  border-bottom: 1px solid #e9e9e9;\n}\n\n.subItem:focus {\n  text-decoration: none;\n}\n\n.subItem:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subItem": `src-settings-submenu-item-styles__subItem`
};
export default ___CSS_LOADER_EXPORT___;
