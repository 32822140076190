import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
  useMutation,
  queryClient,
  setupQueryKeygen,
  queryOptions,
} from "fetcher!sofe";
export { useQueryClient, useQuery, useInfiniteQuery, useMutation, queryClient, queryOptions };

export const { genBaseKey, genQueryKey, genQueries, invalidateEntity } = setupQueryKeygen("global-settings");
