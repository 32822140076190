import React from "react";
import PropTypes from "prop-types";
import styles from "./settings-menu-item.styles.css";
import { CpIcon } from "canopy-styleguide!sofe";

export default class SettingsMenuItem extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    icon: PropTypes.string.isRequired,
    shouldDisplay: PropTypes.bool,
  };

  static defaultProps = {
    shouldDisplay: true,
    url: "",
  };

  render() {
    const { hash } = window.location;

    let childrenRendered = false;
    let isActive = this.props.url.length > 0 && hash.includes(this.props.url);
    let url = this.props.url;

    if (React.Children.count(this.props.children) > 0) {
      React.Children.forEach(this.props.children, (child) => {
        // keeping track of if there are children that need to be rendered; if a top-level item has a child that should be rendered, then it needs to be rendered in order to access the child
        childrenRendered = childrenRendered || child.props.shouldDisplay;

        // if a child of a top-level item has an active url, then the top-level item needs to be active as well
        isActive = isActive || hash.includes(child.props.url);

        // if this component doesn't have a URL, then try to grab the first child that is displayed and use their URL
        if (url.length === 0 && child.props.shouldDisplay) {
          url = child.props.url;
        }
      });
    }

    if (!this.props.shouldDisplay && !childrenRendered) return null;

    return (
      <>
        <a
          key={this.props.title}
          href={`/${url}`}
          className={`${styles.item} ${
            isActive ? `${styles.active} cp-color-app-primary` : "cp-color-app-secondary-text"
          }`}
        >
          <CpIcon
            name={this.props.icon}
            fill={`${isActive ? "var(--cp-color-app-primary)" : "var(--cp-color-app-icon)"}`}
          />
          <span className="cps-margin-left-16">{this.props.title}</span>
        </a>
        <div
          className={`${styles.submenuContainer} ${
            isActive && React.Children.count(this.props.children) ? styles.submenuExpanded : ""
          }`}
        >
          {this.props.children}
        </div>
      </>
    );
  }
}
