// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-settings-menu-item-styles__item {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 14px 0 14px 14px;
}

.src-settings-menu-item-styles__active {
  border-left: 2px solid var(--cps-color-primary);
  padding-left: 12px; /* 14px - 2px for the border */
}

.src-settings-menu-item-styles__item:hover {
  background-color: #f3f3f3;
}

.src-settings-menu-item-styles__item:hover,
.src-settings-menu-item-styles__item:active,
.src-settings-menu-item-styles__item:hover:active,
.src-settings-menu-item-styles__item:focus {
  text-decoration: none;
}

.src-settings-menu-item-styles__submenuContainer {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  transition: max-height 100ms linear, opacity 250ms linear;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0 6px 16px -9px inset rgba(0, 0, 0, 0.24);
}

.src-settings-menu-item-styles__submenuContainer.src-settings-menu-item-styles__submenuExpanded {
  max-height: 40rem;
  overflow: visible;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/settings-menu-item.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,+CAA+C;EAC/C,kBAAkB,EAAE,8BAA8B;AACpD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;;;EAIE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,yDAAyD;EACzD,aAAa;EACb,gBAAgB;EAChB,UAAU;EACV,qDAAqD;AACvD;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".item {\n  height: 40px;\n  display: flex;\n  align-items: center;\n  padding: 14px 0 14px 14px;\n}\n\n.active {\n  border-left: 2px solid var(--cps-color-primary);\n  padding-left: 12px; /* 14px - 2px for the border */\n}\n\n.item:hover {\n  background-color: #f3f3f3;\n}\n\n.item:hover,\n.item:active,\n.item:hover:active,\n.item:focus {\n  text-decoration: none;\n}\n\n.submenuContainer {\n  background-color: #f9f9f9;\n  display: flex;\n  flex-direction: column;\n  transition: max-height 100ms linear, opacity 250ms linear;\n  max-height: 0;\n  overflow: hidden;\n  opacity: 0;\n  box-shadow: 0 6px 16px -9px inset rgba(0, 0, 0, 0.24);\n}\n\n.submenuContainer.submenuExpanded {\n  max-height: 40rem;\n  overflow: visible;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `src-settings-menu-item-styles__item`,
	"active": `src-settings-menu-item-styles__active`,
	"submenuContainer": `src-settings-menu-item-styles__submenuContainer`,
	"submenuExpanded": `src-settings-menu-item-styles__submenuExpanded`
};
export default ___CSS_LOADER_EXPORT___;
