import React from "react";
import { NavContent, TopnavSecondary } from "primary-navbar!sofe";
import { isTemplatePage } from "./settings.helper.js";

export default class SettingsBreadCrumbs extends React.Component {
  render() {
    const titleText = isTemplatePage() ? "Templates" : "Settings";

    return (
      <NavContent style={{ padding: "0px" }} clientMenuPossible={false} hasTopnavSecondary={false}>
        <TopnavSecondary>
          <div className="cps-topnav-secondary__content">
            <ul className="cps-topnav-secondary__content__menu +title">
              <li>{titleText}</li>
            </ul>
          </div>
        </TopnavSecondary>
      </NavContent>
    );
  }
}
