import { fetchAsObservable } from "fetcher!sofe";

type Role = {
  appliedTo: number;
  appliedToTeams: number;
  appliedToUsers: number;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  id: string;
  label: string;
  tenantId: number;
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
};

export function getRolesObs() {
  return fetchAsObservable("/api/roles");
}
export function getRoles() {
  return new Promise<Role[]>((resolve, reject) => {
    fetchAsObservable("/api/roles").subscribe((res: { roles: Role[] }) => resolve(res.roles), reject);
  });
}

export function getRole(id: string) {
  return fetchAsObservable(`/api/roles/${id}`);
}

export function checkRoleName(label: string) {
  return fetchAsObservable(`/api/roles:check-availability?label=${label}`);
}

export function createRole(label: string) {
  return fetchAsObservable("/api/roles", {
    method: "POST",
    body: {
      label,
    },
  });
}

export function editRole(id: string, label: string) {
  return fetchAsObservable(`/api/roles/${id}`, {
    method: "PATCH",
    body: {
      label,
    },
  });
}

export function deleteRole(id: string) {
  return fetchAsObservable(`/api/roles/${id}`, {
    method: "DELETE",
  });
}
