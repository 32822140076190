// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-settings-menu-styles__sidebar {
  position: fixed;
  z-index: 97;
  overflow: auto;
  width: 26.2rem; /* 24rem + 2.4rem */
}

.src-settings-menu-styles__sectionContainer {
  width: calc(100% - 2.4rem * 2); /* 2.4rem on both sides */
  border: 1px solid #e9e9e9;
  background-color: #fff;
  border-radius: 1px;
  margin: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px 0;
}
`, "",{"version":3,"sources":["webpack://./src/settings-menu.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,cAAc;EACd,cAAc,EAAE,mBAAmB;AACrC;;AAEA;EACE,8BAA8B,EAAE,yBAAyB;EACzD,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,cAAc;AAChB","sourcesContent":[".sidebar {\n  position: fixed;\n  z-index: 97;\n  overflow: auto;\n  width: 26.2rem; /* 24rem + 2.4rem */\n}\n\n.sectionContainer {\n  width: calc(100% - 2.4rem * 2); /* 2.4rem on both sides */\n  border: 1px solid #e9e9e9;\n  background-color: #fff;\n  border-radius: 1px;\n  margin: 2.4rem;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  padding: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `src-settings-menu-styles__sidebar`,
	"sectionContainer": `src-settings-menu-styles__sectionContainer`
};
export default ___CSS_LOADER_EXPORT___;
