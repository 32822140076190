import { genQueries, queryOptions } from "src/react-query";
import { fetchAsObservable } from "fetcher!sofe";
import { expand, pluck, reduce } from "rxjs/operators";
import { sortBy } from "lodash";
import { EMPTY } from "rxjs";

function getUsers(
  queryParams: {
    onlyActive?: boolean;
    onlyArchived?: boolean;
    onlyTrashed?: boolean;
    basic?: boolean;
    role?: string;
  } = {}
) {
  const { onlyActive, onlyArchived, onlyTrashed, basic, role } = queryParams;

  const mergedParams = {
    // Default onlyActive to true if no other "only" flags are set
    onlyActive: onlyActive ?? (!onlyArchived && !onlyTrashed),
    onlyTrashed: onlyTrashed,
    onlyArchived: onlyArchived,
    basic: basic ?? true,
    role: role ?? "TeamMember",
  };

  const params = new URLSearchParams();
  Object.entries(mergedParams).forEach(([key, value]) => value && params.append(key, value.toString()));

  return new Promise((resolve, reject) =>
    fetchAsObservable(`/wg/users?${params}`)
      .pipe(pluck("users"))
      .subscribe((users: any) => resolve(sortBy(users, "name")), reject)
  );
}

export function getTeamsObs(page = "1") {
  return fetchAsObservable(`/wg/user-teams?limit=100&page=${page}`);
}

export function getAllTeams() {
  return new Promise((resolve, reject) => {
    getTeamsObs()
      .pipe(
        expand((result: any) => {
          const nextPage = result.meta.paginator.next_page;
          return nextPage ? getTeamsObs(nextPage) : EMPTY;
        }),
        reduce((acc, data: any) => acc.concat(data?.teams), [])
      )
      .subscribe(resolve, reject);
  });
}

export const teamMemberQueries = genQueries("team-members", ({ genKey }) => ({
  teamMembers: ({
    onlyActive,
    onlyArchived,
    onlyTrashed,
    basic,
  }: {
    onlyActive?: boolean;
    onlyArchived?: boolean;
    onlyTrashed?: boolean;
    basic?: boolean;
  }) =>
    queryOptions<any>({
      queryKey: genKey("teamMembers", { onlyActive, onlyArchived, onlyTrashed, basic }),
      queryFn: () => getUsers({ onlyActive, onlyArchived, onlyTrashed, basic }),
      staleTime: 1000 * 60 * 5, // 5 minutes in ms
    }),

  allTeams: () =>
    queryOptions<any>({
      queryKey: genKey("allTeams"),
      queryFn: () => getAllTeams(),
      staleTime: 1000 * 60 * 5, // 5 minutes in ms
    }),
}));
