import React from "react";
import { createRoot } from "react-dom/client";
import { object, func } from "prop-types";
import { CpModal, CpEmptyState, CpButton, constants } from "canopy-styleguide!sofe";
import { useCss, k } from "kremling";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { useHasAccess } from "cp-client-auth!sofe";

LicensePaywallModal.propTypes = {
  invalidLicenses: object,
  redirect: func,
};

export default function LicensePaywallModal(props) {
  const { invalidLicenses, redirect } = props;

  const scope = useCss(css);
  const { t } = useTranslation();

  const canManageLicenses = useHasAccess("company_settings_account_management");

  const onClick = () => {
    if (canManageLicenses) {
      window.location.hash = "/global-settings/account-management";
    } else {
      // prevent the modal from popping up again after reload
      window.localStorage.setItem("global-settings:license-paywall-modal-closed", "true");
      redirect();
    }
    window.location.reload();
  };

  const renderSubText = () => (
    <div className="modal-sub-text">
      <div>{t("You do not have enough licenses within your practice to keep all team members assigned.")}</div>

      {canManageLicenses ? (
        <div className="cp-mt-24 cps-body">
          <strong>{t("Please remove the following:")}</strong>

          {map(invalidLicenses, ({ totalPurchased, totalAssigned }, licenseName) => {
            const count = totalAssigned - totalPurchased;
            if (count < 1) {
              return null;
            }

            return (
              <div key={licenseName}>
                {t("{{count}} users from {{licenseName}}", { count, licenseName: constants.licenseMap[licenseName] })}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="cp-mt-16">{t("Please contact your administrator to fix this issue.")}</div>
      )}
    </div>
  );

  return (
    <CpModal show onClose={() => {}}>
      <div {...scope} className="wrapper">
        <CpButton className="close-button" icon="close-small" aria-label="close" onClick={onClick} />
        <CpModal.Body>
          <div className="modal-body">
            <CpEmptyState
              img="es_caution"
              text={t("License shortage")}
              subText={renderSubText()}
              cta={
                canManageLicenses && (
                  <CpButton className="cp-mt-16" btnType="flat" onClick={onClick}>
                    {t("View my subscription")}
                  </CpButton>
                )
              }
            />
          </div>
        </CpModal.Body>
      </div>
    </CpModal>
  );
}

export function renderLicensePaywallModal(invalidLicenses, redirect) {
  return new Promise(() => {
    const div = document.createElement("div");
    document.body.appendChild(div);

    const root = createRoot(div);
    root.render(<LicensePaywallModal invalidLicenses={invalidLicenses} redirect={redirect} />);
  });
}

const css = k`
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: .8rem;
  }

  .modal-body {
    padding-bottom: 1.6rem;
  }

  .close-button {
    align-self: flex-end;
  }
`;
