import { queryOptions } from "@tanstack/react-query";
import { getRoles } from "src/pages/team/roles.resource";
import { genQueries } from "src/react-query";

export const roleQueries = genQueries("roles", ({ genKey }) => ({
  roles: () =>
    queryOptions({
      queryKey: genKey("roles", {}),
      queryFn: () => getRoles(),
      staleTime: 60000, // 60 seconds
    }),
}));
