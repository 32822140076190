import React from "react";
import PropTypes from "prop-types";
import styles from "./settings-submenu-item.styles.css";

export default class SettingsSubmenuItem extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    shouldDisplay: PropTypes.bool.isRequired,
  };

  render() {
    if (!this.props.shouldDisplay) return null;

    const { hash } = window.location;

    let isActive = hash.includes(this.props.url);

    return (
      <a href={this.props.url} className={`${styles.subItem} cps-gray ${isActive ? "cps-wt-bold" : ""}`}>
        {this.props.title}
      </a>
    );
  }
}
