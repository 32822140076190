import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getTenant() {
  return fetchAsObservable(`/wg/tenants/0`).pipe(pluck("tenants"));
}

export function patchTenant(tenantId, tenantPatch) {
  return fetchAsObservable(`/wg/tenants/${tenantId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      tenants: tenantPatch,
    }),
  }).pipe(pluck("tenants"));
}

export function getKbaCredits() {
  return fetchAsObservable(`/per-use-licenses/KBA`);
}

export function patchZuoraClientRecord(zuoraClient, updateTenant = false) {
  return fetchAsObservable(`/wg/subscriptions/0/clients${updateTenant ? `?update_tenant=${updateTenant}` : ""}`, {
    method: "PATCH",
    body: JSON.stringify(zuoraClient),
  });
}
