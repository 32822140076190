import React, { useRef } from "react";
import styles from "./settings-menu.styles.css";

export default function SettingsMenuContainer(props) {
  const divRef = useRef();

  if (divRef.current) {
    if (!divRef.current.children.length) return null;
  }

  return (
    <div className={styles.sectionContainer} ref={divRef}>
      {props.children}
    </div>
  );
}
